import React from 'react';

import _ from 'lodash';

import styles from './styles.module.scss';

class PrevNextNavigation extends React.Component {
  render() {
    const { onClickHandler, currentIndex, itemCount, labels } = this.props;

    return (
      <div className={styles.container}>
        <div>
          {currentIndex > 0 && (
            <span
              className={styles.item}
              onClick={() => onClickHandler(currentIndex - 1)}>
              <i className="material-icons">keyboard_arrow_left</i>{' '}
              {labels.prev || 'Back'}
            </span>
          )}
        </div>
        <div>
          {!_.isNull(currentIndex) &&
            currentIndex < itemCount - 1 &&
            currentIndex >= 0 && (
              <span
                className={styles.item}
                onClick={() => onClickHandler(currentIndex + 1)}>
                {labels.next || 'Next'}
                <i className="material-icons">keyboard_arrow_right</i>
              </span>
            )}
        </div>
      </div>
    );
  }
}

export default PrevNextNavigation;
