import _ from 'lodash';
import React from 'react';

import { withRouter } from 'react-router-dom';

import { EMPLOYEE_URLS } from 'config/urls';

import { getAuthToken } from 'utils/common';
import { Logo, Page } from 'components/application';

import { LoginForm, ForgotPasswordForm } from './components';

import styles from './styles.module.scss';

class LoginPage extends React.Component {
  state = {
    forgotPasswordIsTriggered: false
  };

  componentWillMount() {
    const token = getAuthToken();
    if (!_.isEmpty(token)) {
      this.props.history.push(EMPLOYEE_URLS.DASHBOARD_PAGE);
    }
  }

  toggleForgotPassword = () => {
    this.setState({
      forgotPasswordIsTriggered: !this.state.forgotPasswordIsTriggered
    });
  };

  render() {
    return (
      <Page>
        <Page.Content>
          <div className={styles.wrapper}>
            <div className={styles.formWrapper}>
              <Logo className={styles.logo} />
              {this.state.forgotPasswordIsTriggered ? (
                <>
                  <ForgotPasswordForm />
                  <div
                    onClick={this.toggleForgotPassword}
                    className={styles.forgotPasswordToggler}>
                    Cancel
                  </div>
                </>
              ) : (
                <>
                  <LoginForm />
                  <div
                    onClick={this.toggleForgotPassword}
                    className={styles.subLink}>
                    Forgot your password?
                  </div>
                  <a
                    href="https://www.adaptai.co/beta/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.subLink}>
                    Not yet registered? Apply here to get involved.
                  </a>
                </>
              )}
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default withRouter(LoginPage);
