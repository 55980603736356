import ReactGA from 'react-ga';

export const GAInitialize = () => {
  ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`, {
    titleCase: false
  });
};

export const GAPageView = path => {
  ReactGA.pageview(path);
};
