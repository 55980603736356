import React from 'react';

import classnames from 'classnames';

import { Menu, Sidebar } from 'semantic-ui-react';

import styles from './styles.module.scss';

class NavigationSidebar extends React.Component {
  render() {
    const { visible, children, className } = this.props;

    return (
      <Sidebar
        as={Menu}
        direction="left"
        icon="labeled"
        className={classnames(styles.sidebar, className)}
        onHide={this.props.toggleSidebar}
        vertical
        visible={visible}
        animation="push"
        width="thin"
        inverted>
        {children}
      </Sidebar>
    );
  }
}
export default NavigationSidebar;
