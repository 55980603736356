import React from 'react';

import _ from 'lodash';

import { getIndividualInsights } from 'sdk';
import { GAPageView } from 'utils/GA';
import { Title, Text, Loader } from 'components/ui';

import styles from './styles.module.scss';

class Insights extends React.Component {
  state = {
    data: null
  };

  componentDidMount() {
    this.fetchInsights();
    GAPageView('/employee/insights');
  }

  fetchInsights = async () => {
    const { data } = await getIndividualInsights();

    if (!_.isEmpty(data)) {
      this.setState({ data });
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <Loader active={_.isEmpty(this.state.data)} />
        <div className={styles.left}>
          <Title className={styles.title}>Working well</Title>
          {_.map(_.get(this.state.data, 'positive'), insight => (
            <div>
              <div className={styles.boxTitle}>{insight.title}</div>
              <Text className={styles.boxContent}>{insight.content} </Text>
            </div>
          ))}
        </div>

        <div className={styles.right}>
          <Title className={styles.title}>needs focus</Title>
          {_.map(_.get(this.state.data, 'negative'), insight => (
            <div>
              <div className={styles.boxTitle}>{insight.title}</div>
              <Text className={styles.boxContent}>{insight.content}</Text>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Insights;
