import React from 'react';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import {
  LOGIN_PAGE_URL,
  RESET_PASSWORD_PAGE_URL,
  REGISTER_PAGE_URL,
  ADMIN_URLS,
  EMPLOYEE_URLS,
  BOT_URL
} from 'config/urls';

import { LoginPage, ResetPasswordPage, RegisterPage } from 'pages';

import { EmployeeDashboardPage, BotPage } from 'pages/Employee';

import {
  AdminAqPage,
  AdminAssessmentPage,
  AdminUnlearnPage
} from 'pages/Admin';

class Routes extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Route
            path={EMPLOYEE_URLS.DASHBOARD_PAGE}
            exact
            component={EmployeeDashboardPage}
          />
          <Route path={BOT_URL} exact component={BotPage} />
          <Route path={ADMIN_URLS.AQ_PAGE} exact component={AdminAqPage} />
          <Route
            path={ADMIN_URLS.ASSESSMENT_PAGE}
            exact
            component={AdminAssessmentPage}
          />
          <Route
            path={ADMIN_URLS.UNLEARN_PAGE}
            exact
            component={AdminUnlearnPage}
          />
          <Route path={ADMIN_URLS.DEFAULT} exact component={AdminAqPage} />

          <Route
            path={RESET_PASSWORD_PAGE_URL}
            exact
            component={ResetPasswordPage}
          />
          <Route path={REGISTER_PAGE_URL} exact component={RegisterPage} />
          <Route path={LOGIN_PAGE_URL} exact component={LoginPage} />
        </Router>
      </React.Fragment>
    );
  }
}

export default Routes;
