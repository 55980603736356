import React from 'react';

import _ from 'lodash';

import {
  AdminPage,
  LoginRequired,
  UserTypeRerouter
} from 'components/application';

import { Title, Loader } from 'components/ui';

import { GAPageView } from 'utils/GA';

import { getCompanyResults } from 'sdk';

import styles from './styles.module.scss';

class AssessmentPage extends React.Component {
  state = {
    data: {}
  };

  componentWillMount() {
    this.fetchCompanyResults();
    GAPageView('/admin/assessment');
  }

  fetchCompanyResults = async () => {
    const { data } = await getCompanyResults();

    if (!_.isEmpty(data)) {
      this.setState({ data });
    }
  };

  render() {
    const { user } = this.props;

    const assessmentData = _.get(this.state.data, 'assessment_page', '');

    return (
      <AdminPage user={user} data={this.state.data}>
        <AdminPage.Body>
          <AdminPage.Content>
            <div className={styles.container}>
              <Title className={styles.title}>The assessment</Title>
              <Loader active={_.isEmpty(assessmentData)} />
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: assessmentData }}
              />
            </div>
          </AdminPage.Content>
        </AdminPage.Body>
      </AdminPage>
    );
  }
}

export default LoginRequired(UserTypeRerouter(AssessmentPage));
