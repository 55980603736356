import _ from 'lodash';
export const PROACTIVE = {
  index: 0,
  dataKey: 'proactivity',
  labels: {
    title: 'Pro-activity',
    leftText: 'Reactive',
    rightText: 'Pro-active',
    color: 'green'
  }
};

export const MOTIVATION = {
  index: 1,
  dataKey: 'motivational_style',
  labels: {
    title: 'Motivation Style',
    leftText: 'Play in defence',
    rightText: 'Play to win',
    color: 'green'
  }
};
export const EXTRAVERSION = {
  index: 2,
  dataKey: 'extraversion',
  labels: {
    title: 'Extraversion',
    fromText: 'Quiet',
    toText: 'Outgoing'
  }
};

export const SELF_DISCIPLINE = {
  index: 3,
  dataKey: 'self_discipline',
  labels: {
    title: 'Self discipline',
    fromText: 'Spontaneous',
    toText: 'Organized'
  }
};

export const OPENNESS = {
  index: 4,
  dataKey: 'openness',
  labels: {
    title: 'Openness',
    fromText: 'Practical',
    toText: 'Imaginative'
  }
};

export const LINE_CHARTS = [EXTRAVERSION, SELF_DISCIPLINE, OPENNESS];
export const ALL_CHARTS = [PROACTIVE, MOTIVATION, ...LINE_CHARTS];

export const getNextActiveItem = (index, charts) => {
  return _.filter(charts, item => item.index === index + 1)[0];
};

export const getPrevActiveItem = (index, charts) => {
  return _.filter(charts, item => item.index === index - 1)[0];
};

export const getActiveItemTitle = (index, charts) =>
  _.get(_.filter(charts, item => item.index === index)[0], 'labels.title');
