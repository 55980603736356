export const ABILITY_TABS = {
  NONE: { index: null, ignore: true, labels: { title: 'Ability' } },
  FIRST: {
    index: 0,
    dataKey: 'resilience',
    labels: {
      color: 'pink',
      title: 'Resilience',
      leftText: 'Rigid',
      rightText: 'Resilient'
    }
  },
  SECOND: {
    index: 1,
    dataKey: 'flexibility',
    labels: {
      title: 'Flexibility',
      leftText: 'Single-focused',
      rightText: 'Flexible',
      color: 'pink'
    }
  },
  THIRD: {
    index: 2,
    dataKey: 'learning_drive',
    labels: {
      title: 'Learning Drive',
      leftText: 'Comfortable',
      rightText: 'Driven to learn',
      color: 'pink'
    }
  },
  FORTH: {
    index: 3,
    dataKey: 'mindset',
    labels: {
      title: 'Mindset',
      leftText: 'Pessimistic',
      rightText: 'Optimistic',
      color: 'pink'
    }
  }
};
