import React from 'react';

import _ from 'lodash';

import { GAPageView } from 'utils/GA';
import { Title, Text } from 'components/ui';
import { ProgressBar, PrevNextNavigation } from 'components/application';

import AbilityIcon from 'assets/images/ability.svg';

import { ABILITY_TABS } from './constants';

import { getPrevNextLabels } from './utils';

import styles from './styles.module.scss';

class Ability extends React.Component {
  state = { activeObject: null };

  componentDidMount() {
    GAPageView('/employee/ability');
  }

  setActiveProgressBar = index => {
    const activeObject = _.filter(ABILITY_TABS, tab => tab.index === index)[0];

    this.setState({
      activeObject
    });
  };

  render() {
    const { data } = this.props;
    const { activeObject } = this.state;

    const activeIndex = _.get(activeObject, 'index', null);

    const abilityData = data;

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <Title className={styles.title}>
            <img
              src={AbilityIcon}
              alt="ability icon"
              className={styles.titleIcon}
            />
            AQ Ability
          </Title>
          {_.map(ABILITY_TABS, tab =>
            tab.ignore ? (
              ''
            ) : (
              <>
                <div className={styles.progressBarAdditional}>
                  <Title className={styles.title}>
                    {_.get(tab, 'labels.title')}
                  </Title>
                  <Text>
                    {_.get(tab, 'dataKey')
                      ? _.get(data[_.get(tab, 'dataKey')], 'description')
                      : _.get(data, 'description')}
                  </Text>
                </div>
                <ProgressBar
                  {...tab.labels}
                  key={tab.index}
                  onClick={() => this.setActiveProgressBar(tab.index)}
                  active={tab.index === activeIndex}
                  disabled={!_.isNull(activeIndex) && tab.index !== activeIndex}
                  className={styles.progressBar}
                  value={_.get(abilityData[tab.dataKey], 'percentages')}
                />
              </>
            )
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.title}>
            {_.get(activeObject, 'labels.title') || 'AQ Ability'}
          </div>
          <div className={styles.description}>
            {_.get(activeObject, 'dataKey')
              ? _.get(data[_.get(activeObject, 'dataKey')], 'description')
              : _.get(data, 'description')}
          </div>
          <PrevNextNavigation
            currentIndex={activeIndex}
            onClickHandler={this.setActiveProgressBar}
            // Here we extract 1 & filter because NULL tab is present in the ABILITY_TABS
            itemCount={Object.keys(ABILITY_TABS).length - 1}
            labels={getPrevNextLabels(
              activeIndex,
              _.filter(ABILITY_TABS, tab => !_.isNull(tab.index))
            )}
          />
        </div>
      </div>
    );
  }
}

export default Ability;
