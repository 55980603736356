import React from 'react';

import styles from './styles.module.scss';

const InputField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <React.Fragment>
    <input
      type="text"
      style={{ background: 'transparent' }}
      className={styles.field}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <div className={styles.error}>* {errors[field.name]}</div>
    )}
  </React.Fragment>
);

export default InputField;
