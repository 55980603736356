import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

class AdminBox extends React.Component {
  render() {
    return (
      <div className={classnames(styles.boxWrapper, this.props.className)}>
        {this.props.chart && (
          <div className={styles.chart}>{this.props.chart}</div>
        )}
        <div className={styles.title}>{this.props.title}</div>
        <div className={styles.content}>{this.props.children}</div>
      </div>
    );
  }
}

export default AdminBox;
