import React from 'react';

import _ from 'lodash';
import classnames from 'classnames';

import styles from './styles.module.scss';

class LineChart extends React.Component {
  containerRef = React.createRef();
  pathRef = React.createRef();
  markerRef = React.createRef();

  componentDidMount() {
    const self = this;
    for (let i = 0; i < 60; i++) {
      setTimeout(() => {
        if (_.get(this.pathRef, 'current.setAttribute')) {
          this.pathRef.current.setAttribute(
            'd',
            `M-10 40 C -10 40, 250 ${40 - 50 * (i / 60)}, 500 40`
          );
        }

        if (_.get(this.markerRef, 'current')) {
          let value = self.props.value * (i / 60);
          this.markerRef.current.style.left = `${value}%`;
          this.markerRef.current.style.top = `${self.getTopOffset(value) *
            (i / 60)}px`;
        }
      }, 13 * i);
    }
  }

  getTopOffset = value => {
    return 10 - (22 * value) / 25 + (11 * value * value) / 1250;
  };

  render() {
    const {
      className,
      value = 50,
      markerColor,
      fromText = '',
      toText = '',
      disabled,
      active,
      onClick
    } = this.props;

    return (
      <>
        <div
          className={classnames(
            styles.container,
            className,
            {
              [styles.disabled]: disabled && !active
            },
            styles.desktop
          )}
          onClick={onClick}>
          <div className={styles.markerContainer}>
            <div className={styles.labels}>
              <div className={styles.leftText}>{fromText}</div>
              <div className={styles.rightText}>{toText}</div>
            </div>
            <div
              className={classnames(styles.marker, styles[markerColor])}
              ref={this.markerRef}
              style={{
                top: `${this.getTopOffset(value)}px`,
                left: `calc(${value}% - 16px)`
              }}
            />
          </div>
          <svg width="550" height="45" ref={this.containerRef}>
            <path
              d="M-10 40 C -10 40, 275 -10, 550 40"
              stroke="#ffffff"
              stroke-width="3"
              fill="transparent"
              ref={this.pathRef}
            />
          </svg>
        </div>
        <div
          className={classnames(
            styles.container,
            className,
            {
              [styles.disabled]: disabled && !active
            },
            styles.mobile
          )}>
          <div className={styles.markerContainer}>
            <div className={styles.labels}>
              <div className={styles.leftText}>{fromText}</div>
              <div className={styles.rightText}>{toText}</div>
            </div>
            <div
              className={classnames(styles.marker, styles[markerColor])}
              ref={this.markerRef}
              style={{
                top: `${this.getTopOffset(value)}px`,
                left: `calc(${value}% - 16px)`
              }}
            />
          </div>
          <svg width="550" height="45" ref={this.containerRef}>
            <path
              d="M-10 40 C -10 40, 275 -10, 550 40"
              stroke="#ffffff"
              stroke-width="3"
              fill="transparent"
            />
          </svg>
        </div>
      </>
    );
  }
}

export default LineChart;
