import React from 'react';
import _ from 'lodash';

import { AdminBox, ProgressBar } from 'components/application';

import { GAPageView } from 'utils/GA';

import styles from './styles.module.scss';

class Environment extends React.Component {
  componentDidMount() {
    GAPageView('/admin/aq/environment');
  }

  render() {
    const { data } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <AdminBox title="AQ Environment">
            {_.get(data, 'description', 'N/A')}
          </AdminBox>
        </div>
        <div className={styles.right}>
          <AdminBox title="Emotional Health" className={styles.item}>
            {_.get(data, 'emotional_health.description', 'N/A')}
            <ProgressBar
              color="blue"
              title="Emotional Health"
              className={styles.progressBar}
              value={_.get(data, 'emotional_health.percentages', 0)}
            />
          </AdminBox>
          <AdminBox title="Community" className={styles.item}>
            {_.get(data, 'community.description', 'N/A')}
            <ProgressBar
              color="blue"
              title="Community"
              className={styles.progressBar}
              value={_.get(data, 'community.percentages', 0)}
            />
          </AdminBox>
          <AdminBox title="Perceived Change" className={styles.item}>
            {_.get(data, 'perceived_change.description', 'N/A')}
            <ProgressBar
              color="blue"
              title="Perceived Change"
              className={styles.progressBar}
              value={_.get(data, 'perceived_change.percentages', 0)}
            />
          </AdminBox>
        </div>
      </div>
    );
  }
}

export default Environment;
