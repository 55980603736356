import React from 'react';

import classnames from 'classnames';

import styles from './styles.module.scss';

class ProgressBar extends React.Component {
  render() {
    const {
      onClick,
      className,
      barContainerClassName,
      value = 0,
      color = 'blue',
      title = 'resilience',
      active = false,
      disabled = false,
      leftText = 'Rigid',
      rightText = 'Resilient'
    } = this.props;

    return (
      <>
        <div
          onClick={onClick}
          className={classnames(
            styles.wrapper,
            styles.desktop,
            { [styles.active]: active && !disabled },
            { [styles.disabled]: disabled && !active },
            className
          )}>
          <div
            className={classnames(styles.barContainer, barContainerClassName)}>
            <div
              className={classnames(styles.progress, styles[color])}
              style={{ width: `${value}%`, transition: 'all 1s ease-in' }}>
              <div className={styles.text}>{title}</div>
            </div>
          </div>
          <div className={styles.textContainer}>
            <div>{leftText}</div>
            <div>{rightText}</div>
          </div>
        </div>
        <div
          className={classnames(
            styles.wrapper,
            styles.mobile,
            { [styles.active]: active && !disabled },
            { [styles.disabled]: disabled && !active },
            className
          )}>
          <div
            className={classnames(styles.barContainer, barContainerClassName)}>
            <div
              className={classnames(styles.progress, styles[color])}
              style={{ width: `${value}%`, transition: 'all 1s ease-in' }}>
              <div className={styles.text}>{title}</div>
            </div>
          </div>
          <div className={styles.textContainer}>
            <div>{leftText}</div>
            <div>{rightText}</div>
          </div>
        </div>
      </>
    );
  }
}

export default ProgressBar;
