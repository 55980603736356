import _ from 'lodash';
import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';

import { resetPassword } from 'sdk';
import { LOGIN_PAGE_URL } from 'config/urls';

import { Page } from 'components/application';
import { FormError, SuccessMessage, InputField } from 'components/ui';

import styles from './styles.module.scss';

class ResetPasswordPage extends React.Component {
  state = {
    token: null,
    errors: [],
    successMessage: ''
  };

  constructor(props) {
    super(props);

    this.validationSchema = yup.object().shape({
      password: yup.string().required('Password is required.'),
      confirm_password: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords do not match')
        .required('Password confirm is required')
    });
  }

  componentDidMount() {
    const { token } = this.props.match.params;
    this.setState({ token });
  }

  handleForm = async (values, { setSubmitting }) => {
    const { token } = this.state;
    const { errors } = await resetPassword({
      ...values,
      token
    });

    setSubmitting(false);

    if (!_.isEmpty(errors)) {
      this.setState({ errors });
    } else {
      this.setState({
        successMessage: 'Your password has been successfully updated.'
      });
    }
  };

  render() {
    const { successMessage } = this.state;

    return (
      <Page>
        <Page.Body>
          <Page.Content className={styles.container}>
            <div className={styles.title}>Reset Password</div>
            {this.state.errors &&
              this.state.errors.map((error, key) => (
                <FormError key={key}>{error.message}</FormError>
              ))}

            {successMessage && (
              <SuccessMessage>{successMessage}</SuccessMessage>
            )}
            <Formik
              onSubmit={this.handleForm}
              validationSchema={this.validationSchema}>
              {form => (
                <Form className={styles.form}>
                  <Field
                    type="password"
                    name="password"
                    component={InputField}
                    placeholder="Enter password"
                  />
                  <Field
                    type="password"
                    name="confirm_password"
                    component={InputField}
                    placeholder="Confirm password"
                  />
                  <button
                    className={styles.submit}
                    type="submit"
                    disabled={form.isSubmitting}>
                    Submit
                  </button>
                  <div
                    onClick={() => this.props.history.push(LOGIN_PAGE_URL)}
                    className={styles.cancel}>
                    Back to Login
                  </div>
                </Form>
              )}
            </Formik>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ResetPasswordPage;
