import _ from 'lodash';

export const getPrevNextLabels = (activeIndex, items) => {
  const itemCount = Object.keys(items).length;

  return {
    prev: activeIndex > 0 && items[activeIndex - 1].labels.title,
    next:
      !_.isNull(activeIndex) &&
      activeIndex < itemCount - 1 &&
      items[activeIndex + 1].labels.title
  };
};
