export const BOT_URL = '/bot';
export const LOGIN_PAGE_URL = '/';
export const REGISTER_PAGE_URL = '/register/:token';
export const RESET_PASSWORD_PAGE_URL = '/reset-password/:token';

export const EMPLOYEE_URLS = {
  DASHBOARD_PAGE: '/report'
};

export const ADMIN_URLS = {
  DEFAULT: '/admin',
  AQ_PAGE: '/admin/aq',
  UNLEARN_PAGE: '/admin/unlearn',
  ASSESSMENT_PAGE: '/admin/assessment'
};
