import React from 'react';

import { Message } from 'semantic-ui-react';

import styles from './styles.module.scss';

class FormError extends React.Component {
  render() {
    return (
      <Message color="red" className={styles.error}>
        {this.props.children}
      </Message>
    );
  }
}

export default FormError;
