import React from 'react';
import _ from 'lodash';

import { AdminBox, ProgressBar, RadialBarChart } from 'components/application';

import { GAPageView } from 'utils/GA';

import styles from './styles.module.scss';

class Overview extends React.Component {
  componentDidMount() {
    GAPageView('/admin/aq/overview');
  }
  render() {
    const { data } = this.props;

    const chartValues = [
      _.get(data, 'environment.overall', 12),
      _.get(data, 'character.overall', 13),
      _.get(data, 'ability.overall', 14)
    ];

    const chartLabels = ['AQ Environment', 'AQ Character', 'AQ Ability'];

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <AdminBox
            title="Overall AQ score"
            chart={
              <RadialBarChart
                labels={chartLabels}
                values={chartValues}
                className={styles.chart}
                total={parseInt(_.get(data, 'overall', 0))}
              />
            }>
            <div className={styles.aqText}>
              {_.get(data, 'description', 'N/A')}
            </div>
          </AdminBox>
        </div>
        <div className={styles.right}>
          <AdminBox title="AQ Change Index" className={styles.item}>
            {_.get(data, 'change_index.description', 'N/A')}
            <ProgressBar
              color="orange"
              title="AQ Change Index"
              className={styles.progressBar}
              value={_.get(data, 'change_index.percentages', 0)}
            />
          </AdminBox>
          <AdminBox title="AQ Re-Skill Index" className={styles.item}>
            {_.get(data, 'reskill_index.description', 'N/A')}
            <ProgressBar
              color="orange"
              title="AQ Re-Skill Index"
              className={styles.progressBar}
              value={_.get(data, 'reskill_index.percentages', 0)}
            />
          </AdminBox>
        </div>
      </div>
    );
  }
}

export default Overview;
