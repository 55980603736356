import React from 'react';

import { Loader as SemanticLoader, Dimmer } from 'semantic-ui-react';

const Loader = ({
  active,
  inverted = false,
  withoutDimmer = false,
  size = 'massive'
}) =>
  withoutDimmer ? (
    <SemanticLoader active={active} inverted={inverted} size={size} />
  ) : (
    <Dimmer active={active} inverted={inverted}>
      <SemanticLoader size={size} />
    </Dimmer>
  );

export default Loader;
