import React from 'react';

import classNames from 'classnames';

import { Navigation } from 'components/application';

import styles from './styles.module.scss';

class PageBody extends React.Component {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classNames(styles.PageBody, className)}>{children}</div>
    );
  }
}

const PageContent = ({ className, children }) => (
  <div className={classNames(styles.PageContent, className)}>{children}</div>
);

class Page extends React.Component {
  render() {
    const {
      className,
      children,
      user,
      changeSelectedTab,
      selectedTab
    } = this.props;

    return (
      <div className={classNames(styles.Page, className)}>
        <Navigation
          user={user}
          changeSelectedTab={changeSelectedTab}
          selectedTab={selectedTab}
        />
        {children}
      </div>
    );
  }
}

Page.Body = PageBody;
Page.Content = PageContent;

export default Page;
