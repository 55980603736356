import React from 'react';

import _ from 'lodash';
import classNames from 'classnames';

import { AdminNavigation } from 'components/application';

import styles from './styles.module.scss';

class PageBody extends React.Component {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classNames(styles.PageBody, className)}>{children}</div>
    );
  }
}

const PageContent = ({ className, children }) => (
  <div className={classNames(styles.PageContent, className)}>{children}</div>
);

class AdminPage extends React.Component {
  render() {
    const {
      className,
      children,
      user,
      data,
      activeTab,
      changeActiveTab
    } = this.props;

    return (
      <div className={classNames(styles.Page, className)}>
        <AdminNavigation
          user={user}
          activeTab={activeTab}
          changeActiveTab={changeActiveTab}
          completedAssessments={_.get(data, 'assessments_complete', 0)}
        />
        {children}
      </div>
    );
  }
}

AdminPage.Body = PageBody;
AdminPage.Content = PageContent;

export default AdminPage;
