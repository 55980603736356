import _ from 'lodash';
import React from 'react';

import {
  LoginRequired,
  Page,
  UserTypeRerouter,
  Continue
} from 'components/application';
import { TabView, Loader } from 'components/ui';
import { scrollToTop } from 'utils/common';

import { DASHBOARD_TABS } from './constants';

import { getResults } from 'sdk';

import styles from './styles.module.scss';

class Dashboard extends React.Component {
  state = {
    selectedTab: DASHBOARD_TABS[0],
    data: {}
  };

  componentWillMount() {
    const { user } = this.props;

    if (
      _.isEmpty(this.state.data) &&
      _.get(user, 'employee.has_completed_survey')
    )
      this.fetchResults();
  }

  fetchResults = async () => {
    const { data } = await getResults();

    if (!_.isEmpty(data)) {
      this.setState({ data }, () => this.changeSelectedTab(0));
    }
  };

  changeSelectedTab = tabIndex => {
    const selectedTab = DASHBOARD_TABS[tabIndex];

    let data = {};

    if (selectedTab === DASHBOARD_TABS[0]) data = this.state.data;
    else if (selectedTab === DASHBOARD_TABS[5])
      data = {
        title: selectedTab.text,
        text: _.get(this.state.data, selectedTab.dataKey),
        html: _.get(selectedTab, 'html', false)
      };
    else data = this.state.data[selectedTab.text];

    this.setState({ selectedTab: { ...selectedTab, data } });
  };

  render() {
    const { selectedTab } = this.state;

    return (
      <Page
        user={this.props.user}
        changeSelectedTab={this.changeSelectedTab}
        selectedTab={selectedTab}>
        <Page.Content>
          <Page.Body>
            <Loader
              active={_.isEmpty(this.state.data)}
              inverted={true}
              withoutDimmer={true}
            />
            {!_.isEmpty(this.state.data) && (
              <>
                <TabView
                  tabsClassname={styles.tabs}
                  tabs={DASHBOARD_TABS}
                  selectedTab={selectedTab}
                  changeSelectedTab={this.changeSelectedTab}
                />
                {selectedTab.index < DASHBOARD_TABS.length - 1 && (
                  <Continue
                    onClick={index => {
                      this.changeSelectedTab(selectedTab.index + 1);
                      scrollToTop();
                    }}
                  />
                )}
              </>
            )}
          </Page.Body>
        </Page.Content>
      </Page>
    );
  }
}

export default LoginRequired(UserTypeRerouter(Dashboard));
