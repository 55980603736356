import React from 'react';

import _ from 'lodash';

import characterImg from 'assets/images/character.svg';

import { Title, Text } from 'components/ui';
import { GAPageView } from 'utils/GA';

import { ProgressBar, LineChart } from 'components/application';

import {
  PROACTIVE,
  MOTIVATION,
  LINE_CHARTS,
  ALL_CHARTS,
  getNextActiveItem,
  getPrevActiveItem,
  getActiveItemTitle
} from './constants';

import styles from './styles.module.scss';

class Character extends React.Component {
  state = { activeItem: null, description: this.props.data.description };

  componentDidMount() {
    GAPageView('/employee/character');
  }

  setActiveItem = item =>
    this.setState({
      activeItem: item.index,
      description: this.props.data[item.dataKey].description
    });

  render() {
    const { activeItem, description } = this.state;
    const { data } = this.props;

    const sectionTitle = getActiveItemTitle(activeItem, ALL_CHARTS);

    const proactivityData = _.get(data, PROACTIVE.dataKey);
    const motivationData = _.get(data, MOTIVATION.dataKey);

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <Title className={styles.titleContainer}>
            <img
              className={styles.icon}
              src={characterImg}
              alt="Character icon"
            />
            AQ Character
          </Title>
          <div className={styles.sectionTitle}>{sectionTitle}</div>
          <Text className={styles.sectionDescription}>{description}</Text>
          {!_.isNull(activeItem) && (
            <div className={styles.navigation}>
              <div>
                {activeItem > 0 && (
                  <span
                    className={styles.item}
                    onClick={() =>
                      this.setActiveItem(
                        getPrevActiveItem(activeItem, ALL_CHARTS)
                      )
                    }>
                    <i className="material-icons">keyboard_arrow_left</i> Back
                  </span>
                )}
              </div>
              <div>
                {activeItem < ALL_CHARTS.length - 1 && (
                  <span
                    className={styles.item}
                    onClick={() =>
                      this.setActiveItem(
                        getNextActiveItem(activeItem, ALL_CHARTS)
                      )
                    }>
                    {getNextActiveItem(activeItem, ALL_CHARTS).labels.title}{' '}
                    <i className="material-icons">keyboard_arrow_right</i>
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.chartAdditional}>
            <Title className={styles.title}>
              {_.get(PROACTIVE, 'labels.title')}
            </Title>
            <Text className={styles.description}>
              {_.get(proactivityData, 'description')}
            </Text>
          </div>
          <ProgressBar
            {...PROACTIVE.labels}
            className={styles.item}
            onClick={() => this.setActiveItem(PROACTIVE)}
            active={PROACTIVE.index === activeItem}
            disabled={!_.isNull(activeItem) && PROACTIVE.index !== activeItem}
            value={_.get(proactivityData, 'percentages')}
          />

          <div className={styles.chartAdditional}>
            <Title className={styles.title}>
              {_.get(MOTIVATION, 'labels.title')}
            </Title>
            <Text className={styles.description}>
              {_.get(motivationData, 'description')}
            </Text>
          </div>

          <ProgressBar
            {...MOTIVATION.labels}
            className={styles.item}
            active={MOTIVATION.index === activeItem}
            disabled={!_.isNull(activeItem) && MOTIVATION.index !== activeItem}
            onClick={() => this.setActiveItem(MOTIVATION)}
            value={_.get(motivationData, 'percentages')}
          />

          {LINE_CHARTS.map(chart => (
            <>
              <div className={styles.chartAdditional}>
                <Title className={styles.title}>
                  {_.get(chart, 'labels.title')}
                </Title>
                <Text className={styles.description}>
                  {_.get(this.props.data[chart.dataKey], 'description')}
                </Text>
              </div>

              <LineChart
                key={chart.index}
                className={styles.lineChartItem}
                active={chart.index === activeItem}
                disabled={!_.isNull(activeItem) && chart.index !== activeItem}
                onClick={() => this.setActiveItem(chart)}
                value={_.get(data[chart.dataKey], 'percentages')}
                {...chart.labels}
              />
            </>
          ))}
        </div>
      </div>
    );
  }
}

export default Character;
