import React from 'react';

import _ from 'lodash';

import { setBackendUrl } from 'utils/common';

import {
  Page,
  LoginRequired,
  UserTypeRerouter,
  BotProgress
} from 'components/application';

import { mountBot, unmountBot } from './utils';

class Bot extends React.Component {
  componentWillMount() {
    const { user } = this.props;
    if (!_.get(user, 'employee.has_completed_survey')) {
      setBackendUrl();
      mountBot();
    }
  }

  componentWillUnmount() {
    unmountBot();
  }

  render() {
    return (
      <Page user={this.props.user}>
        <BotProgress />
      </Page>
    );
  }
}

export default LoginRequired(UserTypeRerouter(Bot));
