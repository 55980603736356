export const BASE_URL = `${process.env.REACT_APP_BASE_BACKEND_URL}/api`;

// Define APIs urls here

export const LOGIN_API_URL = `${BASE_URL}/users/login/`;
export const FORGOT_PASSWORD_API_URL = `${BASE_URL}/users/send-reset-password-email/`;
export const RESET_PASSWORD_API_URL = `${BASE_URL}/users/reset-password/`;
export const REGISTER_API_URL = `${BASE_URL}/users/register/`;
export const USERS_CONSTANTS_URL = `${BASE_URL}/users/fetch-constants/`;
export const COUNTRIES_URL = `${BASE_URL}/users/fetch-countries/`;
export const ME_API_URL = `${BASE_URL}/users/me/`;
export const PRECENT_COMPLETE_URL = `${BASE_URL}/users/precent-completion/`;

export const RESULTS_API_URL = `${BASE_URL}/users/results/`;
export const INDIVIDUAL_INSIGHTS_API_URL = `${BASE_URL}/users/individual-insights/`;
export const COMPANY_INSIGHTS_API_URL = `${BASE_URL}/users/company-insights/`;
export const COMPANY_RESULTS_API_URL = `${BASE_URL}/users/company-results/`;
