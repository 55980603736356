export const ENVIRONMENT_PROGRESS_BARS = [
  {
    labels: {
      title: 'emotional health',
      leftText: 'Overworked',
      rightText: 'Balanced'
    },
    data: {},
    dataKey: 'emotional_health'
  },
  {
    labels: {
      title: 'community',
      leftText: 'Solitary',
      rightText: 'Supportive'
    },
    data: {},
    dataKey: 'community'
  },
  {
    labels: {
      title: 'perceived need to change',
      leftText: 'Low',
      rightText: 'High'
    },
    data: {},
    dataKey: 'perceived_change'
  }
];
