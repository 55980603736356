import React from 'react';

import _ from 'lodash';
import classnames from 'classnames';

import { scrollToTop } from 'utils/common';

import styles from './styles.module.scss';

const Tab = ({ active, onClick, children }) => (
  <div
    className={classnames(styles.tab, { [styles.active]: active })}
    onClick={onClick}>
    {children}
  </div>
);

class Sidebar extends React.Component {
  render() {
    const { tabs, className, activeTab, changeActiveTab } = this.props;

    return (
      <div className={classnames(styles.container, className)}>
        {_.map(tabs.TOP, tab => (
          <Tab
            active={activeTab.index === tab.index}
            onClick={() => {
              changeActiveTab(tab.index);
              scrollToTop();
            }}>
            AQ {tab.text}
          </Tab>
        ))}
        <div className={styles.divider} />
        {_.map(tabs.BOTTOM, tab => (
          <Tab
            active={activeTab.index === tab.index}
            onClick={() => {
              changeActiveTab(tab.index);
              scrollToTop();
            }}>
            {tab.text}
          </Tab>
        ))}
      </div>
    );
  }
}

export default Sidebar;
