import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';

import Tab from './Tab';

import styles from './styles.module.scss';

class TabView extends React.Component {
  render() {
    const {
      tabs,
      tabContentClassname,
      changeSelectedTab,
      selectedTab,
      tabsClassname
    } = this.props;

    const Content = selectedTab.content;

    return (
      !_.isEmpty(tabs) && (
        <div className={styles.wrapper}>
          <div className={classnames(tabsClassname, styles.tabContainer)}>
            {_.map(
              tabs,
              (tab, key) =>
                !tab.ignore && (
                  <Tab
                    key={key}
                    active={selectedTab.text === tab.text}
                    onClick={() => changeSelectedTab(key)}
                    icon={tab.icon}>
                    {tab.withAqAddition && 'AQ '}
                    {tab.text}
                  </Tab>
                )
            )}
          </div>
          <div className={classnames(styles.tabContent, tabContentClassname)}>
            <Content data={_.get(selectedTab, 'data')} />
          </div>
        </div>
      )
    );
  }
}

export { TabView };
