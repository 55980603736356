import React from 'react';

import classnames from 'classnames';

import styles from './styles.module.scss';

class BotProgressBar extends React.Component {
  render() {
    const { value = 0 } = this.props;

    return (
      <div class={styles.progress}>
        <div
          class={classnames(
            styles.progressBar,
            styles.progressBarWarning,
            styles.progressBarStriped,
            styles.active
          )}
          style={{ width: value + '%' }}
        />
      </div>
    );
  }
}

export default BotProgressBar;
