import React from 'react';

import styles from './styles.module.scss';

const Continue = ({ onClick }) => (
  <div className={styles.continueWrapper}>
    <span className={styles.continueContainer} onClick={onClick}>
      <span className={styles.arrow}>
        <i className="material-icons">arrow_forward</i>
      </span>
      Continue
    </span>
  </div>
);

export default Continue;
