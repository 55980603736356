import _ from 'lodash';
import React from 'react';

import { AdminBox, ProgressBar } from 'components/application';

import { GAPageView } from 'utils/GA';

import styles from './styles.module.scss';

class Ability extends React.Component {
  componentDidMount() {
    GAPageView('/admin/aq/ability');
  }

  render() {
    const { data } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <AdminBox title="AQ Ability">
            {_.get(data, 'description', 'N/A')}
          </AdminBox>
        </div>
        <div className={styles.right}>
          <AdminBox title="Resilience" className={styles.abilityItem}>
            {_.get(data, 'resilience.description', 'N/A')}
            <ProgressBar
              color="pink"
              title="Resilience"
              className={styles.progressBar}
              value={_.get(data, 'resilience.percentages', 0)}
            />
          </AdminBox>
          <AdminBox title="Flexibility" className={styles.abilityItem}>
            {_.get(data, 'flexibility.description', 'N/A')}
            <ProgressBar
              color="pink"
              title="Flexibility"
              className={styles.progressBar}
              value={_.get(data, 'flexibility.percentages', 0)}
            />
          </AdminBox>
          <AdminBox title="Learning Drive" className={styles.abilityItem}>
            {_.get(data, 'learning_drive.description', 'N/A')}
            <ProgressBar
              color="pink"
              title="Learning Drive"
              className={styles.progressBar}
              value={_.get(data, 'learning_drive.percentages', 0)}
            />
          </AdminBox>
          <AdminBox title="Mindset" className={styles.abilityItem}>
            {_.get(data, 'mindset.description', 'N/A')}

            <ProgressBar
              color="pink"
              title="Mindset"
              className={styles.progressBar}
              value={_.get(data, 'mindset.percentages', 0)}
            />
          </AdminBox>
        </div>
      </div>
    );
  }
}

export default Ability;
