import _ from 'lodash';
import queryString from 'query-string';

const NEXT_KEY = 'next';

export const parseQueryString = searchParams => {
  return queryString.parse(searchParams);
};

export const buildUrlFromNextRedirect = url => {
  const params = parseQueryString(url);
  const originalUrl = _.get(params, NEXT_KEY, null);

  return `${originalUrl}`;
};

export const getNextSearch = url => {
  return `${NEXT_KEY}=${url}`;
};

export const getNextUrl = (url, nextUrl) => {
  return `${url}?${getNextSearch(nextUrl)}`;
};

export const stringifyParams = params => queryString.stringify(params);
