import React from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { withRouter } from 'react-router-dom';
import { ADMIN_URLS, EMPLOYEE_URLS } from 'config/urls';
import { Formik, Form, Field } from 'formik';

import { buildUrlFromNextRedirect } from 'utils/urls';
import { setAuthToken } from 'utils/common';
import { login } from 'sdk';

import { FormError, InputField } from 'components/ui';

import styles from './styles.module.scss';

class LoginForm extends React.Component {
  state = {
    errors: [],
    formFields: {
      email: '',
      password: ''
    }
  };
  constructor(props) {
    super(props);

    this.validationSchema = yup.object().shape({
      email: yup
        .string()
        .email('Email is invalid.')
        .required('Email is required.'),
      password: yup.string().required('Password is required.')
    });
  }

  handleForm = async (values, { setSubmitting }) => {
    const { email, password } = values;

    const { data, errors } = await login({ email, password });

    if (!_.isEmpty(data)) {
      setAuthToken(data.token);

      const { search } = this.props.history.location;
      let nextUrl = EMPLOYEE_URLS.AQ_PAGE;

      if (search && !_.isEmpty(search)) {
        nextUrl = buildUrlFromNextRedirect(search);
      }

      this.props.history.push(
        nextUrl || _.get(data, 'is_admin')
          ? ADMIN_URLS.AQ_PAGE
          : EMPLOYEE_URLS.DASHBOARD_PAGE
      );
    }

    if (!_.isEmpty(errors)) {
      this.setState({ errors });
    }

    setSubmitting(false);
  };

  handleFieldChange = (field, event) =>
    this.setState({
      formFields: { ...this.state.formFields, [field]: event.target.value }
    });

  render() {
    const { errors } = this.state;

    return (
      <div className={styles.container}>
        {errors &&
          errors.map((error, key) => (
            <FormError key={key}>{error.message}</FormError>
          ))}
        <Formik
          onSubmit={this.handleForm}
          validationSchema={this.validationSchema}>
          {form => (
            <Form className={styles.form}>
              <Field
                type="email"
                name="email"
                component={InputField}
                placeholder="Your work email address"
              />
              <Field
                type="password"
                name="password"
                component={InputField}
                placeholder="Password"
              />
              <button
                className={styles.submit}
                type="submit"
                disabled={form.isSubmitting}>
                Log in
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(LoginForm);
