export const RadialBarChartOptions = customLabel => ({
  plotOptions: {
    radialBar: {
      startAngle: -180,
      endAngle: 180,
      hollow: {
        size: '40%',
        background: 'transparent'
      },
      track: {
        opacity: 0,
        margin: 10,
        dropShadow: {
          enabled: true,
          top: 3,
          left: 3,
          blur: 10,
          opacity: 0.3
        }
      },
      dataLabels: {
        name: {
          fontSize: '25px'
        },
        value: {
          show: false
        },
        total: {
          show: true,
          label: customLabel || 0,
          color: 'white'
        }
      }
    }
  },
  colors: ['#00DAFB', '#14BF63', '#E454A1']
});
