import _ from 'lodash';
import React from 'react';

import { Loader } from 'components/ui';

import { fetchUserConstants } from 'sdk';

const WithConstants = WrappedComponent => {
  class HOC extends React.Component {
    state = {
      constants: null
    };

    fetchConstants = async () => {
      const { data } = await fetchUserConstants();

      if (!_.isEmpty(data)) this.setState({ constants: data.constants });
    };

    componentWillMount() {
      this.fetchConstants();
    }

    render() {
      const { match, ...rest } = this.props;
      const { constants } = this.state;

      return (
        <React.Fragment>
          <Loader active={_.isEmpty(constants)} />
          {constants && (
            <WrappedComponent match={match} constants={constants} {...rest} />
          )}
        </React.Fragment>
      );
    }
  }

  return HOC;
};

export default WithConstants;
