import _ from 'lodash';
import React from 'react';

import {
  LoginRequired,
  AdminPage,
  Sidebar,
  UserTypeRerouter,
  Continue
} from 'components/application';
import { Loader } from 'components/ui';
import { scrollToTop } from 'utils/common';
import { EMPLOYEE_URLS } from 'config/urls';

import { getCompanyResults } from 'sdk';

import {
  Overview,
  Ability,
  Character,
  Environment,
  Insights,
  Download
} from './components';

import { DEFAULT_SIDEBAR_ITEMS } from './constants';

import styles from './styles.module.scss';

class AqPage extends React.Component {
  state = { activeTab: DEFAULT_SIDEBAR_ITEMS.TOP.OVERVIEW, data: {} };

  changeActiveTab = activeTab =>
    this.setState({
      activeTab: _.filter(
        { ...DEFAULT_SIDEBAR_ITEMS.TOP, ...DEFAULT_SIDEBAR_ITEMS.BOTTOM },
        tab => tab.index === activeTab
      )[0]
    });
  componentWillMount() {
    if (!_.isEmpty(this.props.user.companyadmin)) this.fetchCompanyResults();
  }

  fetchCompanyResults = async () => {
    const { data, errors } = await getCompanyResults();

    if (!_.isEmpty(data)) {
      this.setState({ data });
    }

    if (!_.isEmpty(errors) && errors[0].field === 'assesments_complete') {
      this.props.history.push(EMPLOYEE_URLS.DASHBOARD_PAGE);
    }
  };

  getActiveComponent = () => {
    switch (this.state.activeTab.index) {
      case DEFAULT_SIDEBAR_ITEMS.TOP.OVERVIEW.index:
        return <Overview data={this.state.data} />;

      case DEFAULT_SIDEBAR_ITEMS.TOP.ABILITY.index:
        const data = this.state.data[DEFAULT_SIDEBAR_ITEMS.TOP.ABILITY.text];
        return <Ability data={data} />;

      case DEFAULT_SIDEBAR_ITEMS.TOP.CHARACTER.index:
        return (
          <Character
            data={this.state.data[DEFAULT_SIDEBAR_ITEMS.TOP.CHARACTER.text]}
          />
        );

      case DEFAULT_SIDEBAR_ITEMS.TOP.ENVIRONMENT.index:
        return (
          <Environment
            data={this.state.data[DEFAULT_SIDEBAR_ITEMS.TOP.ENVIRONMENT.text]}
          />
        );

      case DEFAULT_SIDEBAR_ITEMS.TOP.INSIGHTS.index:
        return <Insights />;

      case DEFAULT_SIDEBAR_ITEMS.BOTTOM.DOWNLOAD.index:
        return <Download changeActiveTab={this.changeActiveTab} />;

      default:
        return <Overview />;
    }
  };

  render() {
    const { user } = this.props;

    const { activeTab } = this.state;

    return (
      <AdminPage
        user={user}
        data={this.state.data}
        activeTab={activeTab}
        changeActiveTab={this.changeActiveTab}>
        <AdminPage.Body>
          <Sidebar
            className={styles.sidebar}
            tabs={DEFAULT_SIDEBAR_ITEMS}
            activeTab={this.state.activeTab}
            changeActiveTab={this.changeActiveTab}
          />
          <AdminPage.Content>
            <Loader active={_.isEmpty(this.state.data)} />
            {this.getActiveComponent()}
            {activeTab.index <
              Object.keys(DEFAULT_SIDEBAR_ITEMS.TOP).length && (
              <Continue
                onClick={() => {
                  this.changeActiveTab(activeTab.index + 1);
                  scrollToTop();
                }}
              />
            )}
          </AdminPage.Content>
        </AdminPage.Body>
      </AdminPage>
    );
  }
}

export default LoginRequired(UserTypeRerouter(AqPage));
