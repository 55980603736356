import React from 'react';

import classnames from 'classnames';

import styles from './styles.module.scss';

class Title extends React.Component {
  render() {
    return (
      <div className={classnames(styles.title, this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}

export default Title;
