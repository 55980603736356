import axios from 'axios';

import { getConfig } from 'utils/common';

import {
  LOGIN_API_URL,
  FORGOT_PASSWORD_API_URL,
  RESET_PASSWORD_API_URL,
  REGISTER_API_URL,
  ME_API_URL,
  RESULTS_API_URL,
  INDIVIDUAL_INSIGHTS_API_URL,
  COMPANY_INSIGHTS_API_URL,
  USERS_CONSTANTS_URL,
  COMPANY_RESULTS_API_URL,
  COUNTRIES_URL,
  PRECENT_COMPLETE_URL
} from './backendUrls';

export { BASE_URL } from './backendUrls';

export const requestSdk = async sdk => {
  let result = { data: {}, errors: [], success: false };

  try {
    const response = await sdk();

    result.data = response.data;
    result.success = true;
  } catch (error) {
    if (error.response.status === 401) {
      logout();
    }

    if (error.response.status === 404) {
      result.errors = [{ message: 'Not found' }];
    } else {
      result.errors = error.response.data.errors;
    }
  }

  return result;
};

export const get = (url, params = {}) => {
  return axios.get(url, { ...getConfig(), params });
};

export const post = (url, data) => {
  return axios.post(url, data, getConfig());
};

export const login = async data => {
  return await requestSdk(() => post(LOGIN_API_URL, data));
};

export const logout = () => {
  localStorage.removeItem('token');

  window.location.reload();
};

export const register = async data => {
  return await requestSdk(() => post(REGISTER_API_URL, data));
};

export const forgotPassword = async data => {
  return await requestSdk(() => post(FORGOT_PASSWORD_API_URL, data));
};

export const resetPassword = async data => {
  return await requestSdk(() => post(RESET_PASSWORD_API_URL, data));
};

export const me = async () => {
  return await requestSdk(() => get(ME_API_URL));
};

export const getResults = async () => {
  return await requestSdk(() => get(RESULTS_API_URL));
};

export const getIndividualInsights = async () => {
  return await requestSdk(() => get(INDIVIDUAL_INSIGHTS_API_URL));
};

export const getCompanyInsights = async () => {
  return await requestSdk(() => get(COMPANY_INSIGHTS_API_URL));
};

export const fetchUserConstants = async () => {
  return await requestSdk(() => get(USERS_CONSTANTS_URL));
};

export const getCompanyResults = async () => {
  return await requestSdk(() => get(COMPANY_RESULTS_API_URL));
};

export const fetchCountries = async () => {
  return await requestSdk(() => get(COUNTRIES_URL));
};

export const fetchPrecentComplete = async () => {
  return await requestSdk(() => get(PRECENT_COMPLETE_URL));
};
