export const mountBot = () => {
  let script = document.createElement('script');
  script.setAttribute('id', 'botScript');

  script.src = process.env.REACT_APP_BOT_SOURCE_URL;
  document.getElementsByTagName('head')[0].appendChild(script);

  const botButton = document.getElementById('botbutton');

  if (botButton) botButton.style.visibility = 'visible';
};

export const unmountBot = () => {
  const bot = document.getElementById('botScript');
  const botButton = document.getElementById('botbutton');
  const botContainer = document.getElementById('quriobotWidgetContainer');

  if (bot) bot.remove();
  if (botButton) botButton.style.visibility = 'hidden';
  if (botContainer) botContainer.remove();
};
