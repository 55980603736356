import React from 'react';

import classnames from 'classnames';
import { Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { logout } from 'sdk';
import { ADMIN_URLS, EMPLOYEE_URLS } from 'config/urls';

import { scrollToTop } from 'utils/common';
import {
  Logo,
  SectionDropdown,
  NavigationSidebar
} from 'components/application';

import { DEFAULT_SIDEBAR_ITEMS } from 'pages/Admin/AqPage/constants';

import NavLinks from './NavLinks';

import styles from './styles.module.scss';

class AdminNavigation extends React.Component {
  state = { visible: false };

  handleSidebarShow = () => this.setState({ visible: true });
  handleSidebarHide = () => this.setState({ visible: false });

  handleChangeActiveTabClick = index => {
    this.props.changeActiveTab(index);
    scrollToTop();
  };

  redirectToIndividualView = () =>
    this.props.history.push(EMPLOYEE_URLS.DASHBOARD_PAGE);

  render() {
    const { user, activeTab, completedAssessments = 0 } = this.props;

    const { visible } = this.state;

    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.left}>
              <div
                className={classnames(styles.mobileIcon, {
                  [styles.active]: visible
                })}
                onClick={this.handleSidebarShow}>
                <i className="material-icons">{visible ? 'close' : 'menu'}</i>
              </div>
              <Logo
                className={styles.logo}
                onClick={() => {
                  this.props.changeActiveTab
                    ? this.handleChangeActiveTabClick(
                        DEFAULT_SIDEBAR_ITEMS.TOP.OVERVIEW.index
                      )
                    : this.props.history.push(ADMIN_URLS.AQ_PAGE);
                }}
              />
              {activeTab && (
                <SectionDropdown
                  className={styles.dropdown}
                  items={DEFAULT_SIDEBAR_ITEMS.TOP}
                  selectedTab={activeTab}
                  action={this.handleChangeActiveTabClick}
                  user={user}
                  adminView={true}
                />
              )}

              <div className={styles.navLinks}>
                <NavLinks />
              </div>
            </div>
            <div className={styles.right}>
              <Dropdown
                text={`Welcome, ${user && user.name}`}
                className={styles.desktopDropdown}>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.redirectToIndividualView}>
                    Individual View
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className={styles.additional}>
                {completedAssessments} Assessments completed
              </div>
            </div>
          </div>
        </div>
        <NavigationSidebar
          visible={visible}
          toggleSidebar={this.handleSidebarHide}
          className={styles.sidebar}>
          <NavLinks />
        </NavigationSidebar>
      </>
    );
  }
}

export default withRouter(AdminNavigation);
