import React from 'react';

import _ from 'lodash';

import { Title, Text } from 'components/ui';
import { RadialBarChart } from 'components/application';

import { GAPageView } from 'utils/GA';

import styles from './styles.module.scss';

class Aq extends React.Component {
  componentDidMount() {
    GAPageView('/employee/aq');
  }

  render() {
    const { data } = this.props;

    const chartValues = [
      _.get(data, 'environment.overall', 0),
      _.get(data, 'character.overall', 0),
      _.get(data, 'ability.overall', 0)
    ];

    const chartLabels = ['AQ Environment', 'AQ Character', 'AQ Ability'];

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <Title className={styles.title}>your aq score</Title>
          <Text className={styles.description}>
            {_.get(data, 'description')}
          </Text>
        </div>
        <div className={styles.right}>
          <RadialBarChart
            className={styles.chart}
            labels={chartLabels}
            values={chartValues}
            total={parseInt(_.get(data, 'overall'), 10)}
          />
        </div>
      </div>
    );
  }
}

export default Aq;
