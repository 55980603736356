import React from 'react';

import classnames from 'classnames';

import styles from './styles.module.scss';

class Text extends React.Component {
  render() {
    const { className, html } = this.props;

    return html ? (
      <div
        className={classnames(styles.text, className)}
        dangerouslySetInnerHTML={{ __html: this.props.children }}
      />
    ) : (
      <div className={classnames(styles.text, className)}>
        {this.props.children}
      </div>
    );
  }
}

export default Text;
