import React from 'react';

import { Title, Text } from 'components/ui';

import styles from './styles.module.scss';

const TitleAndText = ({ data }) => {
  return (
    <div className={styles.container}>
      <Title className={styles.title}>{data.title}</Title>
      <Text className={styles.text} html={data.html}>
        {data.text}
      </Text>
    </div>
  );
};

export default TitleAndText;
