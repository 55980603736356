import React from 'react';

import _ from 'lodash';

import { ADMIN_URLS, EMPLOYEE_URLS, BOT_URL } from 'config/urls';

const UserTypeRerouter = WrappedComponent => {
  class HOC extends React.Component {
    componentWillMount() {
      const { user } = this.props;

      const is_company_admin = !_.isEmpty(user.companyadmin);
      const has_completed_survey = _.get(
        user,
        'employee.has_completed_survey',
        false
      );
      this.userTypeRedirect(is_company_admin, has_completed_survey);
    }

    userTypeRedirect = (is_company_admin, has_completed_survey) => {
      const current_path = this.props.history.location.pathname;
      const admin_paths = _.values(ADMIN_URLS);

      if (is_company_admin) {
        if (admin_paths.includes(current_path)) return;
        if (has_completed_survey)
          this.props.history.push(EMPLOYEE_URLS.DASHBOARD_PAGE);
        else this.props.history.push(BOT_URL);
      } else {
        if (has_completed_survey)
          this.props.history.push(EMPLOYEE_URLS.DASHBOARD_PAGE);
        else this.props.history.push(BOT_URL);
      }
    };

    render() {
      const { user, match, ...rest } = this.props;

      if (user) {
        return <WrappedComponent match={match} user={user} {...rest} />;
      }
      return null;
    }
  }

  return HOC;
};

export default UserTypeRerouter;
