import React from 'react';

import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import { logout } from 'sdk';
import { ADMIN_URLS } from 'config/urls';

import styles from './styles.module.scss';

const NavLinks = () => (
  <>
    <NavLink
      to={ADMIN_URLS.AQ_PAGE}
      activeClassName={styles.active}
      className={styles.navLink}>
      aq results
    </NavLink>
    <NavLink
      to={ADMIN_URLS.ASSESSMENT_PAGE}
      activeClassName={styles.active}
      className={styles.navLink}>
      assessment
    </NavLink>
    <NavLink
      to={ADMIN_URLS.UNLEARN_PAGE}
      activeClassName={styles.active}
      className={styles.navLink}>
      unlearn
    </NavLink>
    <div className={styles.mobileOnly}>
      <Divider />
      <div className={classnames(styles.navLink)} onClick={logout}>
        Logout
      </div>
    </div>
  </>
);

export default NavLinks;
