import React from 'react';

import styles from './styles.module.scss';

import { fetchPrecentComplete } from 'sdk';
import { BotProgressBar } from 'components/application';

class BotProgress extends React.Component {
  state = {
    precentComplete: 0
  };

  componentWillMount() {
    this.fetchData();
    setInterval(this.fetchData, 4000);
  }

  fetchData = async () => {
    const { data } = await fetchPrecentComplete();
    this.setState({ precentComplete: data.precent_complete });
  };

  render() {
    return (
      <div className={styles.progressBarContainer}>
        <BotProgressBar value={this.state.precentComplete} />
      </div>
    );
  }
}

export default BotProgress;
