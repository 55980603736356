import {
  Aq,
  Ability,
  Character,
  Environment,
  Insights,
  TitleAndText
} from './components';

import AbilityIcon from 'assets/images/ability.svg';
import CharacterIcon from 'assets/images/character.svg';
import EnvironmentIcon from 'assets/images/environment.svg';
import InsightsIcon from 'assets/images/insights.svg';

export const DASHBOARD_TABS = [
  {
    text: 'your aq',
    data: {},
    content: Aq,
    index: 0
  },
  {
    text: 'ability',
    data: {},
    content: Ability,
    icon: AbilityIcon,
    index: 1,
    withAqAddition: true
  },
  {
    text: 'character',
    data: {},
    content: Character,
    icon: CharacterIcon,
    index: 2,
    withAqAddition: true
  },
  {
    text: 'environment',
    data: {},
    content: Environment,
    icon: EnvironmentIcon,
    index: 3,
    withAqAddition: true
  },
  {
    text: 'insights',
    data: {},
    content: Insights,
    icon: InsightsIcon,
    index: 4,
    withAqAddition: true
  },
  {
    text: 'The assessment',
    data: {},
    index: 5,
    content: TitleAndText,
    ignore: true,
    dataKey: 'assessment_page',
    html: true
  }
];
