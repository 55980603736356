import React from 'react';

import { withRouter } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react';

import styles from './styles.module.scss';

const TermsCheckbox = ({ name, form, onChange }) => (
  <Checkbox
    name={name}
    className={styles.checkbox}
    onChange={onChange}
    label={
      <>
        <label>
          I have read and understood the{' '}
          <a
            href="https://www.adaptai.co/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}>
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="https://www.adaptai.co/terms-and-conditions/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}>
            Terms and Conditions
          </a>{' '}
          and agree to all.
        </label>
        {form.touched[name] && form.errors[name] && (
          <p className={styles.checkboxErrors}>{form.errors[name]}</p>
        )}
      </>
    }
  />
);
export default withRouter(TermsCheckbox);
