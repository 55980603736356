import React from 'react';

import _ from 'lodash';
import classnames from 'classnames';
import * as yup from 'yup';

import { Formik, Form, FastField } from 'formik';

import { register, fetchCountries } from 'sdk';
import { LOGIN_PAGE_URL, EMPLOYEE_URLS } from 'config/urls';

import { FormError, InputField, SelectField } from 'components/ui';
import { Page, Logo, WithConstants } from 'components/application';

import { setAuthToken } from 'utils/common';

import { prepareConstants } from './utils';
import { TermsCheckbox } from './components';

import styles from './styles.module.scss';

const OTHER_JOB_FUNCTION = 17;
const OTHER_JOB_LEVEL = 12;

class RegisterPage extends React.Component {
  state = {
    errors: [],
    countries: []
  };

  constructor(props) {
    super(props);

    this.validationSchema = yup.object().shape({
      email: yup
        .string()
        .email('Email is invalid.')
        .required('Email is required.'),
      password: yup.string().required('Password is required.'),
      name: yup.string().required('Name is required'),
      gender: yup.string().required('Gender is required'),
      age: yup.string().required('Age is required'),
      job_function: yup.string().required('Job Function is required'),
      job_level: yup.string().required('Job Level is required'),
      work_tenure: yup.string().required('Work Tenure is required'),
      country: yup.string().required('Country is required'),
      terms: yup
        .bool()
        .required(
          'You have to agree with the Privacy Policy And Terms & Conditions to continue.'
        )
        .oneOf(
          [true],
          'You have to agree with the Privacy Policy And Terms & Conditions to continue.'
        )
    });
  }
  componentDidMount() {
    const { token } = this.props.match.params;

    this.setState({
      token
    });

    this.fetchCountriesData();
  }

  fetchCountriesData = async () => {
    const { data } = await fetchCountries();

    if (data) {
      const countries = _.map(data, country => ({
        text: country.name,
        value: country.id
      }));

      this.setState({ countries });
    }
  };

  handleForm = async (values, { setSubmitting }) => {
    const { token } = this.state;

    const { data, errors } = await register({ ...values, token });

    if (!_.isEmpty(errors)) {
      setSubmitting(false);
      return this.setState({ errors });
    } else {
      this.setState({ errors: [] });
      setAuthToken(data.auth_token);

      this.props.history.push(EMPLOYEE_URLS.DASHBOARD_PAGE);
    }
  };

  render() {
    const { constants } = this.props;

    const { countries } = this.state;

    return (
      <Page>
        <div className={styles.container}>
          <Logo className={styles.logo} />
          <Formik
            onSubmit={this.handleForm}
            validationSchema={this.validationSchema}>
            {form => (
              <Form className={styles.form}>
                {this.state.errors &&
                  this.state.errors.map((error, key) => (
                    <FormError key={key}>{error.message}</FormError>
                  ))}
                <FastField
                  type="email"
                  name="email"
                  component={InputField}
                  placeholder="Enter email"
                />
                <FastField
                  type="password"
                  name="password"
                  component={InputField}
                  placeholder="Enter password"
                />
                <FastField
                  name="name"
                  component={InputField}
                  placeholder="Enter name"
                />
                <FastField
                  fluid
                  search
                  name="gender"
                  placeholder="Select Gender"
                  className={styles.select}
                  options={prepareConstants(
                    _.get(constants, 'users.GENDER_CHOICES', [])
                  )}
                  component={SelectField}
                />
                <FastField
                  fluid
                  search
                  name="age"
                  placeholder="Select Age"
                  className={styles.select}
                  options={prepareConstants(
                    _.get(constants, 'users.AGE_CHOICES', [])
                  )}
                  component={SelectField}
                />
                <FastField
                  fluid
                  search
                  name="country"
                  placeholder="Select Country"
                  className={styles.select}
                  options={countries}
                  component={SelectField}
                />
                <FastField
                  fluid
                  search
                  name="job_function"
                  placeholder="Select Job Function"
                  className={styles.select}
                  options={prepareConstants(
                    _.get(constants, 'users.JOB_FUNCTION_CHOICES', [])
                  )}
                  component={SelectField}
                />
                {form.values.job_function === OTHER_JOB_FUNCTION && (
                  <FastField
                    name="other_job_function"
                    component={InputField}
                    placeholder="Please specify"
                  />
                )}
                <FastField
                  fluid
                  search
                  name="job_level"
                  placeholder="Select Job Level"
                  className={styles.select}
                  options={prepareConstants(
                    _.get(constants, 'users.JOB_LEVEL_CHOICES', [])
                  )}
                  component={SelectField}
                />
                {form.values.job_level === OTHER_JOB_LEVEL && (
                  <FastField
                    name="other_job_level"
                    component={InputField}
                    placeholder="Please specify"
                  />
                )}
                <FastField
                  fluid
                  search
                  name="work_tenure"
                  placeholder="Work tenure at company"
                  className={styles.select}
                  options={prepareConstants(
                    _.get(constants, 'users.WORK_TENURE_CHOICES', [])
                  )}
                  component={SelectField}
                />
                <FastField
                  name="terms"
                  onChange={(e, data) => {
                    form.setFieldValue('terms', data.checked);
                    form.setFieldTouched('terms', true);
                  }}
                  component={TermsCheckbox}
                />
                <button
                  className={classnames(styles.submit, {
                    [styles.disabled]: form.isSubmitting
                  })}
                  disabled={form.isSubmitting || !form.isValid}
                  type="submit">
                  Register
                </button>
              </Form>
            )}
          </Formik>
          <div
            onClick={() => this.props.history.push(LOGIN_PAGE_URL)}
            className={styles.cancel}>
            Back to Login
          </div>
        </div>
      </Page>
    );
  }
}

export default WithConstants(RegisterPage);
