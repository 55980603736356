export const DEFAULT_SIDEBAR_ITEMS = {
  TOP: {
    OVERVIEW: { index: 1, text: 'overview' },
    ABILITY: { index: 2, text: 'ability' },
    CHARACTER: { index: 3, text: 'character' },
    ENVIRONMENT: { index: 4, text: 'environment' },
    INSIGHTS: { index: 5, text: 'insights' }
  },
  BOTTOM: {
    DOWNLOAD: { index: 6, text: 'download' }
  }
};
