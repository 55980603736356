import React from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { Form, Formik, Field } from 'formik';

import { forgotPassword } from 'sdk';

import { FormError, SuccessMessage, InputField } from 'components/ui';

import styles from './styles.module.scss';

class ForgotPasswordForm extends React.Component {
  state = {
    successMessage: '',
    errors: []
  };
  constructor(props) {
    super(props);

    this.validationSchema = yup.object().shape({
      email: yup
        .string()
        .email('Email is invalid.')
        .required('Email is required.')
    });
  }

  handleForm = async (values, { setSubmitting }) => {
    const { errors } = await forgotPassword({ ...values });

    setSubmitting(false);

    if (!_.isEmpty(errors)) {
      return this.setState({ errors, successMessage: '' });
    } else {
      this.setState({
        successMessage: "An email is on it's way.",
        errors: []
      });
    }
  };

  render() {
    const { errors, successMessage } = this.state;

    return (
      <div className={styles.container}>
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        {errors &&
          errors.map((error, key) => (
            <FormError key={key}>{error.message}</FormError>
          ))}
        <Formik onSubmit={this.handleForm}>
          {form => (
            <Form className={styles.form}>
              <Field
                type="email"
                name="email"
                component={InputField}
                placeholder="Enter your email"
              />
              <button
                className={styles.submit}
                type="submit"
                disabled={form.isSubmitting}>
                Send email
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ForgotPasswordForm;
