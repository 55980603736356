import React from 'react';

import Chart from 'react-apexcharts';

import { RadialBarChartOptions as options } from './constants';

const RadialBarChart = ({ values, labels, total, className }) => {
  let customOptions = options(total);
  return (
    <Chart
      className={className}
      style={{ height: 350 }}
      type="radialBar"
      options={{ ...customOptions, labels: labels }}
      series={values}
    />
  );
};
export default RadialBarChart;
