import _ from 'lodash';
import React from 'react';

import { LOGIN_PAGE_URL } from 'config/urls';

import { getAuthToken } from 'utils/common';
import { getNextUrl } from 'utils/urls';
import { me, logout } from 'sdk';

const LoginRequired = WrappedComponent => {
  class HOC extends React.Component {
    state = {
      user: null
    };

    componentWillMount() {
      const token = getAuthToken();
      if (!_.isEmpty(token) && !_.isNil(token)) {
        this.fetchMe();
      } else {
        this.redirectToLogin();
      }
    }

    redirectToLogin = () => {
      const { pathname } = this.props.history.location;
      this.props.history.push(getNextUrl(LOGIN_PAGE_URL, pathname));
    };

    fetchMe = async () => {
      const { data } = await me();

      if (!_.isEmpty(data)) {
        this.setState({ user: data });
      } else {
        logout();
        this.redirectToLogin();
      }
    };

    render() {
      const { match, ...rest } = this.props;
      const { user } = this.state;
      if (user) {
        return <WrappedComponent match={match} user={user} {...rest} />;
      }
      return null;
    }
  }

  return HOC;
};

export default LoginRequired;
