import React from 'react';

import _ from 'lodash';

import { Loader, Title } from 'components/ui';

import { getCompanyResults } from 'sdk';

import {
  AdminPage,
  LoginRequired,
  UserTypeRerouter
} from 'components/application';

import styles from './styles.module.scss';

class UnlearnPage extends React.Component {
  state = {
    data: {}
  };

  componentWillMount() {
    this.fetchCompanyResults();
  }

  fetchCompanyResults = async () => {
    const { data } = await getCompanyResults();

    if (!_.isEmpty(data)) {
      this.setState({ data });
    }
  };

  render() {
    const { user } = this.props;

    const unlearnData = _.get(this.state.data, 'unlearn_page', '');

    return (
      <AdminPage user={user} data={this.state.data}>
        <AdminPage.Body>
          <AdminPage.Content>
            <div className={styles.container}>
              <Title className={styles.title}>Unlearn</Title>
              <Loader active={_.isEmpty(unlearnData)} />
              <div dangerouslySetInnerHTML={{ __html: unlearnData }} />
            </div>
          </AdminPage.Content>
        </AdminPage.Body>
      </AdminPage>
    );
  }
}

export default LoginRequired(UserTypeRerouter(UnlearnPage));
