import React from 'react';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { logout } from 'sdk';
import { ADMIN_URLS, EMPLOYEE_URLS, BOT_URL } from 'config/urls';

import { Dropdown, Divider } from 'semantic-ui-react';

const SectionDropdown = ({
  action,
  items,
  className,
  selectedTab,
  children,
  user,
  adminView = false,
  ...props
}) => {
  const switchView = () => {
    const current_path = props.history.location.pathname;

    props.history.push(
      adminView ? EMPLOYEE_URLS.DASHBOARD_PAGE : ADMIN_URLS.DEFAULT
    );

    if (current_path === BOT_URL) window.location.reload();
  };
  return (
    <Dropdown
      inline
      text={
        _.toUpper(_.get(selectedTab, 'text')) || `Hey, ${user.employee.name}`
      }
      className={className}
      direction="left">
      <Dropdown.Menu>
        {children}
        {!children &&
          _.map(items, item => (
            <Dropdown.Item
              key={item.index}
              text={_.toUpper(item.text)}
              onClick={() => action(item.index)}
              active={_.get(selectedTab, 'index') === item.index}
            />
          ))}
        {!_.isEmpty(items) || (children && <Divider />)}
        {_.get(user, 'companyadmin') && (
          <Dropdown.Item onClick={switchView}>
            {adminView ? 'INDIVIDUAL VIEW' : 'COMPANY VIEW'}
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={logout}>LOGOUT</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default withRouter(SectionDropdown);
