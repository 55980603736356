import _ from 'lodash';

export const setAuthToken = token => {
  localStorage.setItem('token', token);
};

export const setBackendUrl = () => {
  localStorage.setItem('backendUrl', process.env.REACT_APP_BASE_BACKEND_URL);
};

export const getAuthToken = () => {
  return localStorage.getItem('token');
};

export const getConfig = () => {
  const token = getAuthToken();

  let config = {
    'Content-Type': 'application/json'
  };

  if (!_.isNil(token)) {
    config.headers = {
      Authorization: `JWT ${token}`
    };
  }

  return config;
};

export const extendObject = (obj, newObj) => ({ ...obj, ...newObj });

export const scrollToTop = () =>
  document.querySelector(`body`).scrollIntoView({
    behavior: 'smooth'
  });
