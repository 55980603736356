import React from 'react';

import { Message } from 'semantic-ui-react';

import styles from './styles.module.scss';

const SuccessMessage = ({ children }) => (
  <Message color="green" className={styles.successMessage}>
    {children}
  </Message>
);

export default SuccessMessage;
