import React from 'react';

import _ from 'lodash';
import classnames from 'classnames';
import { Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { logout } from 'sdk';
import { ADMIN_URLS, EMPLOYEE_URLS, BOT_URL } from 'config/urls';

import { Logo, SectionDropdown } from 'components/application';
import { scrollToTop } from 'utils/common';

import { DASHBOARD_TABS } from 'pages/Employee/Dashboard/constants';

import styles from './styles.module.scss';

class Navigation extends React.Component {
  changeSelectedTabHandler = index => {
    this.props.changeSelectedTab(index);
    scrollToTop();
  };

  redirectToCompanyView = () => {
    const current_path = this.props.history.location.pathname;

    this.props.history.push(ADMIN_URLS.DEFAULT);

    if (current_path === BOT_URL) window.location.reload();
  };

  render() {
    const { user, changeSelectedTab, selectedTab } = this.props;

    const specialTabs = _.filter(DASHBOARD_TABS, tab => tab.ignore);

    return (
      <>
        <div className={styles.NavigationWrapper}>
          <div className={styles.container}>
            <div
              className={classnames(styles.leftNavItems, {
                [styles.centered]: !user
              })}>
              <Logo
                className={styles.logo}
                onClick={() =>
                  this.props.changeSelectedTab
                    ? this.changeSelectedTabHandler(0)
                    : this.props.history.push(EMPLOYEE_URLS.DASHBOARD_PAGE)
                }
              />
              {user && (
                <SectionDropdown
                  selectedTab={selectedTab}
                  className={styles.sectionDropdown}
                  action={this.changeSelectedTabHandler}
                  items={
                    _.get(user, 'employee.has_completed_survey')
                      ? DASHBOARD_TABS
                      : []
                  }
                  user={user}
                />
              )}
            </div>
            <div className={styles.rightNavItems}>
              <div className={styles.navItems}>
                {_.get(user, 'employee.has_completed_survey') &&
                  _.map(specialTabs, tab => (
                    <div
                      className={styles.item}
                      onClick={() => changeSelectedTab(tab.index)}>
                      {tab.text}
                    </div>
                  ))}

                {user && (
                  <Dropdown text={`Hey, ${user && user.name}`}>
                    <Dropdown.Menu>
                      {_.get(user, 'companyadmin') && (
                        <Dropdown.Item onClick={this.redirectToCompanyView}>
                          Company View
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item className={styles.logout} onClick={logout}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Navigation);
