import React from 'react';
import { Dropdown, Label } from 'semantic-ui-react';

const SelectField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props // search and options
}) => {
  return (
    <div>
      <Dropdown
        selection
        onClose={() => setFieldTouched(field.name, true, true)}
        onChange={(e, data) => setFieldValue(field.name, data.value)}
        {...props}
      />
      {touched[field.name] && errors[field.name] && (
        <Label basic color="red" pointing="left">
          {errors[field.name]}
        </Label>
      )}
    </div>
  );
};

export default SelectField;
