import _ from 'lodash';
import React from 'react';
import { AdminBox, ProgressBar, LineChart } from 'components/application';

import { GAPageView } from 'utils/GA';

import styles from './styles.module.scss';

class Character extends React.Component {
  componentDidMount() {
    GAPageView('/admin/aq/character');
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <AdminBox title="AQ Character" className={styles.item}>
            {_.get(data, 'description', 'N/A')}
          </AdminBox>
          <AdminBox title="Personality" className={styles.item}>
            {_.get(data, 'extraversion.description', 'N/A')}
            <LineChart
              className={styles.lineChart}
              fromText="Quiet"
              toText="Outgoing"
              value={_.get(data, 'extraversion.percentages', 0)}
            />
            {_.get(data, 'openness.description', 'N/A')}
            <LineChart
              className={styles.lineChart}
              value={_.get(data, 'openness.percentages', 0)}
              fromText="Practical"
              toText="Imaginative"
            />
            {_.get(data, 'self_discipline.description', 'N/A')}
            <LineChart
              className={styles.lineChart}
              value={_.get(data, 'self_discipline.percentages', 0)}
              fromText="Spontaneous"
              toText="Organized"
            />
          </AdminBox>
        </div>

        <div className={styles.right}>
          <AdminBox title="Pro-activity" className={styles.item}>
            {_.get(data, 'proactivity.description', 'N/A')}
            <ProgressBar
              color="green"
              title="Pro-activity"
              className={styles.progressBar}
              value={_.get(data, 'proactivity.percentages', 0)}
            />
          </AdminBox>
          <AdminBox title="Motivation Style" className={styles.item}>
            {_.get(data, 'motivational_style.description', 'N/A')}

            <ProgressBar
              color="green"
              title="Motivation Style"
              className={styles.progressBar}
              value={_.get(data, 'motivational_style.percentages', 0)}
            />
          </AdminBox>
        </div>
      </div>
    );
  }
}

export default Character;
