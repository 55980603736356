import React from 'react';

import Routes from 'config/routes';

class App extends React.Component {
  render() {
    return <Routes />;
  }
}

export default App;
