import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Tab = ({ children, onClick, active, icon }) => {
  return (
    <div
      onClick={onClick}
      className={classnames(styles.tab, { [styles.active]: active })}>
      {icon && <img src={icon} className={styles.icon} alt={children} />}
      {children}
    </div>
  );
};

export default Tab;
