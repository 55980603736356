import React from 'react';

import _ from 'lodash';

import { Title, Text } from 'components/ui';
import { GAPageView } from 'utils/GA';
import { ProgressBar, PrevNextNavigation } from 'components/application';

import EnvironmentIcon from 'assets/images/environment.svg';

import { ENVIRONMENT_PROGRESS_BARS } from './constants';
import { getPrevNextLabels } from './utils';

import styles from './styles.module.scss';

class Environment extends React.Component {
  state = {
    activeProgressBar: {},
    bars: []
  };

  componentDidMount() {
    GAPageView('/employee/environment');
  }

  componentWillMount() {
    const { data } = this.props;

    const presentKeys = Object.keys(data);

    let bars = _.filter(ENVIRONMENT_PROGRESS_BARS, bar =>
      presentKeys.includes(bar.dataKey)
    );

    bars = _.map(bars, bar => {
      return { ...bar, data: _.get(data, bar.dataKey) };
    });

    this.setState({ bars });
  }

  setActiveProgressBar = (method, key) => {
    let activeProgressBar = {};

    if (method === 'dataKey')
      activeProgressBar = _.filter(
        this.state.bars,
        bar => bar.dataKey === key
      )[0];
    else activeProgressBar = this.state.bars[key];

    this.setState({
      activeProgressBar:
        activeProgressBar.dataKey === this.state.activeProgressBar.dataKey
          ? {}
          : activeProgressBar
    });
  };

  render() {
    const { data } = this.props;

    const { bars, activeProgressBar } = this.state;

    const environmentData = data;

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <Title className={styles.title}>
            <img
              src={EnvironmentIcon}
              className={styles.icon}
              alt="environment icon"
            />
            AQ Environment
          </Title>
          <div className={styles.subTitle}>
            {_.get(activeProgressBar, 'labels.title')}
          </div>
          <Text className={styles.description}>
            {!_.isEmpty(activeProgressBar)
              ? _.get(activeProgressBar, 'data.description')
              : _.get(environmentData, 'description')}
          </Text>
          <PrevNextNavigation
            onClickHandler={key => this.setActiveProgressBar('index', key)}
            currentIndex={_.indexOf(bars, activeProgressBar)}
            itemCount={bars.length}
            labels={getPrevNextLabels(_.indexOf(bars, activeProgressBar), bars)}
          />
        </div>

        <div className={styles.right}>
          {_.map(bars, barData => (
            <>
              <div className={styles.progressBarAdditional}>
                <Title className={styles.title}>
                  {_.get(barData, 'labels.title')}
                </Title>
                <Text>
                  {_.get(barData, 'dataKey')
                    ? _.get(data[_.get(barData, 'dataKey')], 'description')
                    : _.get(barData, 'description')}
                </Text>
              </div>
              <ProgressBar
                {...barData.labels}
                key={barData.dataKey}
                className={styles.progressBar}
                active={activeProgressBar.dataKey === barData.dataKey}
                disabled={
                  activeProgressBar.dataKey &&
                  activeProgressBar.dataKey !== barData.dataKey
                }
                onClick={() =>
                  this.setActiveProgressBar('dataKey', barData.dataKey)
                }
                value={_.get(environmentData, `${barData.dataKey}.percentages`)}
              />
            </>
          ))}
        </div>
      </div>
    );
  }
}

export default Environment;
