import React from 'react';

import _ from 'lodash';

import { getCompanyInsights } from 'sdk';

import { Loader } from 'components/ui';

import { GAPageView } from 'utils/GA';

import styles from './styles.module.scss';

const Insight = ({ title = '', content }) => (
  <div className={styles.insight}>
    <div className={styles.title}>{title}</div>
    <div className={styles.content}>{content}</div>
  </div>
);

class Insights extends React.Component {
  state = {
    data: null
  };

  componentDidMount() {
    this.fetchInsights();
    GAPageView('/admin/aq/insights');
  }

  fetchInsights = async () => {
    const { data } = await getCompanyInsights();

    if (!_.isEmpty(data)) {
      this.setState({ data });
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <Loader active={_.isEmpty(this.state.data)} />
        <div className={styles.left}>
          <div className={styles.title}>working well</div>
          {_.map(_.get(this.state.data, 'positive', []), insight => (
            <Insight {...insight} />
          ))}
        </div>
        <div className={styles.right}>
          <div className={styles.title}>needs focus</div>
          {_.map(_.get(this.state.data, 'negative', []), insight => (
            <Insight {...insight} />
          ))}
        </div>
      </div>
    );
  }
}

export default Insights;
