import _ from 'lodash';

export const getPrevNextLabels = (activeIndex, items) => {
  const prevItem = _.filter(items, item => item.index === activeIndex - 1)[0];
  const nextItem = _.filter(items, item => item.index === activeIndex + 1)[0];

  const itemCount = Object.keys(items).length;

  return {
    prev: activeIndex > 0 && prevItem.labels.title,
    next:
      !_.isNull(activeIndex) &&
      activeIndex < itemCount - 1 &&
      nextItem.labels.title
  };
};
