import React from 'react';

import { Modal } from 'semantic-ui-react';

import { DEFAULT_SIDEBAR_ITEMS } from 'pages/Admin/AqPage/constants';

class Download extends React.Component {
  render() {
    return (
      <div>
        <Modal
          size="mini"
          open={true}
          onClose={() =>
            this.props.changeActiveTab(DEFAULT_SIDEBAR_ITEMS.TOP.OVERVIEW.index)
          }>
          <Modal.Content>
            <Modal.Description>This feature is coming soon.</Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default Download;
